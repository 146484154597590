
import React from 'react';
import Header from "./Components/Header/Header";
import LoginForm from "./Components/Login/LoginForm";
import Login from "./Components/Login/LoginForm";
function App() {

    return (
        <div >
            <Header/>

        </div>
    );
}

export default App;
